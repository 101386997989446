<template>
  <div :id="echartId" :ref="echartId" class="echart-main"></div>
</template>

<script>
import { EleResize } from '@/utils/esresize.js'
import * as echarts from 'echarts'
let data = [[220, 182, 191, 234, 290, 330, 310]]
let yMax = 500
let dataShadow = []
for (let i = 0; i < data.length; i++) {
  dataShadow.push(yMax)
}
export default {
  // props: ['dataXAis', 'dataList',"echartId"],
  props: {
    chartColor: {
      type: Array,
      default: () => ['#1b4965', '#30b2ca10', '#4EC8D310']
    },
    echartId: {
      type: String,
      default: 'echartId'
    },
    rotateEdg: {
      type: String,
      default: function () {
        return '0'
      }
    },
    dataAxis: {
      type: Array,
      default: () => ['2019', '2020', '2021', '2022']
    }
  },
  data () {
    return {
      options: {
        dataset: {
          dimensions: [
            'year',
            '第一季度',
            '第二季度',
            '第三季度',
            '第四季度',
            '平均值'
          ],
          source: [
            {
              year: '2019',
              第一季度: 43.3,
              第二季度: 85.8,
              第三季度: 93.7,
              第四季度: 93.7,
              平均值: 80
            },
            {
              year: '2020',
              第一季度: 83.1,
              第二季度: 73.4,
              第三季度: 55.1,
              第四季度: 93.7,
              平均值: 90
            },
            {
              year: '2021',
              第一季度: 86.4,
              第二季度: 65.2,
              第三季度: 82.5,
              第四季度: 93.7,
              平均值: 120
            },
            {
              year: '2022',
              第一季度: 72.4,
              第二季度: 53.9,
              第三季度: 39.1,
              第四季度: 93.7,
              平均值: 55
            }
          ]
        },
        grid: {
          top: 40,
          bottom: 10,
          left: 10,
          right: 20,
          containLabel: true
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        xAxis: {
          type: 'category',
          axisLabel: {
            inside: false,
            color: '#fff',
            rotate: this.rotateEdg
          },
          axisTick: {
            show: false
          },
          axisLine: {
            show: true,
            lineStyle: {
                    color: "#fff",
                    width: 1,
                    type: "solid"
            }

          },
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed',
              color: '#354D5F'
            }
          }

          // z: 10,
        },
        yAxis: {
          axisLine: {
            show: true,
            lineStyle: {
                    color: "#ffffff80",
                    width: 1,
                    type: "solid"
            }

          },
          axisTick: {
            show: false
          },
          //刻度延长线
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed',
              color: '#354D5F'
            }
          },
          axisLabel: {
            color: '#999'
          }
        },
        dataZoom: [
          {
            type: 'inside'
          }
        ],
        series: [
          {
            type: 'bar',
            barGap: 0,
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: '#159AFF' },
                { offset: 1, color: 'rgba(21, 154, 255, 0)' }
              ])
            }
          },
          {
            type: 'bar',
            barGap: 0,
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: '#159AFF' },
                { offset: 1, color: 'rgba(21, 154, 255, 0)' }
              ])
            }
          },
          {
            type: 'bar',
            barGap: 0,
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: '#159AFF' },
                { offset: 1, color: 'rgba(21, 154, 255, 0)' }
              ])
            }
          },
          {
            type: 'bar',
            barGap: 0,
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: '#159AFF' },
                { offset: 1, color: 'rgba(21, 154, 255, 0)' }
              ])
            }
          },
          {
            type: 'line',
            barGap: 0,
            itemStyle: {
              color: '#159AFF'
            }
          }
        ]
      }
    }
  },
  watch: {
    // dataXAis: function (val) {
    //   this.options.xAxis.data = val
    // },
    // dataList: function (val) {
    //   this.options.series[0].data = val
    //   this.getShowPie()
    // }
  },
  mounted () {
    // setTimeout(() => {
    this.getShowPie()
    // }, 1000)
  },
  methods: {
    getShowPie () {
      let _this = this
      // this.options.xAxis.data = this.dataXAis
      // this.options.series[0].data = this.dataList;
      // this.options.series[0].data = data;
      // this.options.series[0].markPoint.data = data.map((item,index) => {
      //   var obj = {
      //     // value: item,
      //     symbol: 'path://M0,10 L10,10 C5.5,10 5.5,5 5,0 C4.5,5 4.5,10 0,10 z',
      //           xAxis: index, //放需要展示图片的x轴索引
      //             yAxis: item, //y轴对应的值
      //     // symbolSize: [60, 60],
      //   };
      //   return obj;
      // });
      var myCharts = this.$echarts.init(document.getElementById(this.echartId))
      var echartsid = document.getElementById(this.echartId)
      //   var myChart = this.$echarts.init(echartsid)
      myCharts.setOption(this.options)

      let listener = function () {
        myCharts.resize()
      }
      EleResize.on(echartsid, listener)
      const zoomSize = 6
      myCharts.on('click', function (params) {
        _this.$emit('showModal', params.target)
        // console.log(dataAxis[Math.max(params.dataIndex - zoomSize / 2, 0)]);
        // myCharts.dispatchAction({
        //   type: "dataZoom",
        //   startValue: _this.dataAxis[Math.max(params.dataIndex - zoomSize / 2, 0)],
        //   endValue:
        //     _this.dataAxis[
        //       Math.min(params.dataIndex + zoomSize / 2, data.length - 1)
        //     ],
        // });
      })
    }
  }
}
</script>

<style>
.echart-main {
  height: 100%;
  width: 100%;
}
</style>
