<template>
  <div class="industrialChain">
    <el-container class="">
      <el-aside class="aside">
        <div class="aside_top">
          <chart-common :isShowHeader="false">
            <div class="itemList2">
              <div class="tabs">
                <div
                  :class="houseType == 1 ? 'left zjActviedClass' : 'left'"
                  @click="selectHouseType(1)"
                >
                  <img
                    :src="
                      houseType == 1
                        ? require('../assets/images/industryHousing/typeLeft_actived.png')
                        : require('../assets/images/industryHousing/typeLeft.png')
                    "
                    alt=""
                  />
                  <span>园区情况</span>
                </div>
                <div
                  :class="houseType == 2 ? 'right zjActviedClass' : 'right'"
                  @click="selectHouseType(2)"
                >
                  <img
                    :src="
                      houseType == 2
                        ? require('../assets/images/industryHousing/typeRight_actived.png')
                        : require('../assets/images/industryHousing/typeRight.png')
                    "
                    alt=""
                  />
                  <span>用房管理</span>
                </div>
              </div>
              <ul v-show="houseType == 1">
                <li v-for="(item, index) in itemList" :key="index">
                  <div class="title">
                    <img
                      src="../assets/images/industryMap/left/03@2x.png"
                      alt=""
                    />
                    <span>{{ item.name }}</span>
                  </div>
                  <p>{{ item.value }}</p>
                </li>
              </ul>
              <ul v-show="houseType == 2">
                <div class="infoTitle">
                  <img
                    src="../assets/images/industryMap/left/erji@2x.png"
                    alt=""
                  />
                  <span>使用情况</span>
                </div>
                <li v-for="(item, index) in houseUsingList" :key="index">
                  <div class="title">
                    <img
                      src="../assets/images/industryMap/left/03@2x.png"
                      alt=""
                    />
                    <span>{{ item.name }}</span>
                  </div>
                  <p>{{ item.value }}</p>
                </li>
                <div class="infoTitle">
                  <img
                    src="../assets/images/industryMap/left/erji@2x.png"
                    alt=""
                  />
                  <span>租赁情况</span>
                </div>
                <li v-for="(item, index) in rentStatusList" :key="index">
                  <div class="title">
                    <img
                      src="../assets/images/industryMap/left/03@2x.png"
                      alt=""
                    />
                    <span>{{ item.name }}</span>
                  </div>
                  <p>{{ item.value }}</p>
                </li>
              </ul>
            </div>
          </chart-common>
        </div>
        <div class="aside_top aside_center">
          <div class="itemList">
            <div
              :class="ActivedItemType == 0 ? 'itemOne activedItem' : 'itemOne'"
              @click="selectItemType('0')"
            >
              <div class="left">
                <img
                  src="../assets/images/industryHousing/changcu@2x.png"
                  alt=""
                />
                <span>厂房</span>
              </div>
              <div class="right">
                <div class="num">15731478.71m²/25042347.8m²</div>
                <div class="bar">
                  <div class="activedBar" :style="'width:'+(15731478.71/25042347.8)*100+'%'"></div>
                </div>
              </div>
            </div>
            <div
              :class="ActivedItemType == 1 ? 'itemOne activedItem' : 'itemOne'"
              @click="selectItemType('1')"
            >
              <div class="left">
                <img
                  src="../assets/images/industryHousing/changcu@2x.png"
                  alt=""
                />
                <span>研发用房</span>
              </div>
              <div class="right">
                <div class="num">2618737.529m²/25042347.8m²</div>
                <div class="bar">
                  <div class="activedBar" :style="'width:'+(2618737.529/25042347.8)*100+'%'"></div>
                </div>
              </div>
            </div>
            <div
              :class="ActivedItemType == 2 ? 'itemOne activedItem' : 'itemOne'"
              @click="selectItemType('2')"
            >
              <div class="left">
                <img
                  src="../assets/images/industryHousing/changcu@2x.png"
                  alt=""
                />
                <span>物流仓储用房</span>
              </div>
              <div class="right">
                <div class="num">226648.4131m²/25042347.8m²</div>
                <div class="bar">
                  <div class="activedBar" :style="'width:'+(226648.4131/25042347.8)*100+'%'"></div>
                </div>
              </div>
            </div>
            <div
              :class="ActivedItemType == 3 ? 'itemOne activedItem' : 'itemOne'"
              @click="selectItemType('3')"
            >
              <div class="left">
                <img
                  src="../assets/images/industryHousing/changcu@2x.png"
                  alt=""
                />
                <span>产业配套用房</span>
              </div>
              <div class="right">
                <div class="num">6465483.146m²/25042347.8m²</div>
                <div class="bar">
                  <div class="activedBar" :style="'width:'+(6465483.146/25042347.8)*100+'%'"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="aside_bottom">
          <div class="chart2">
            <div class="tabs">
              <div
                :class="zjType == 1 ? 'left zjActviedClass' : 'left'"
                @click="selectType(1)"
              >
                <img
                  :src="
                    zjType == 1
                      ? require('../assets/images/industryHousing/typeLeft_actived.png')
                      : require('../assets/images/industryHousing/typeLeft.png')
                  "
                  alt=""
                />
                <span>平均租金</span>
              </div>
              <div
                :class="zjType == 2 ? 'right zjActviedClass' : 'right'"
                @click="selectType(2)"
              >
                <img
                  :src="
                    zjType == 2
                      ? require('../assets/images/industryHousing/typeRight_actived.png')
                      : require('../assets/images/industryHousing/typeRight.png')
                  "
                  alt=""
                />
                <span>建议租金</span>
              </div>
            </div>
            <div class="chart">
              <flex-line :echartId="'chart3'"></flex-line>
            </div>
          </div>
        </div>
      </el-aside>
      <el-main class="main">
        <div class="map_chart">
          <!-- <Map ref="map" :type="industryType"></Map> -->
          <div id="map"></div>
        </div>
        <div class="table" style="display:none">
          <el-table
            align="center"
            :data="tableData"
            style="width: 100%"
            :header-cell-style="tableHeadStyle"
            :row-class-name="tableRowClassName"
            :cell-style="tableCellStyle"
          >
            <el-table-column prop="id" label="ID" width="50" fixed="left">
            </el-table-column>
            <el-table-column prop="jd" label="所属街道" width="80" fixed="left">
            </el-table-column>
            <el-table-column
              prop="community"
              label="所属社区"
              width="80"
              fixed="left"
            >
            </el-table-column>
            <el-table-column
              prop="park"
              label="所属园区"
              width="120"
              fixed="left"
            >
            </el-table-column>
            <el-table-column prop="phone" label="详细地址" width="180">
            </el-table-column>
            <el-table-column prop="website" label="用途" width="180">
            </el-table-column>
            <el-table-column prop="isGs" label="建筑基层面积(㎡)" width="180">
            </el-table-column>
            <el-table-column prop="product" label="建筑面积(㎡)" width="180">
            </el-table-column>
            <el-table-column prop="tax" label="层数" width="180">
            </el-table-column>
            <el-table-column prop="tax" label="单层建筑面积(㎡)" width="180">
            </el-table-column>
            <el-table-column prop="tax" label="建成时间" width="180">
            </el-table-column>
            <el-table-column prop="tax" label="建成年限" width="180">
            </el-table-column>
            <el-table-column prop="tax" label="建筑结构" width="190">
            </el-table-column>
            <el-table-column prop="tax" label="首层承重(公斤/㎡)" width="210">
            </el-table-column>
            <el-table-column prop="tax" label="非首层承重(公斤/㎡)" width="210">
            </el-table-column>
            <el-table-column prop="tax" label="层高(m)" width="210">
            </el-table-column>
            <el-table-column prop="tax" label="自用面积(㎡)" width="210">
            </el-table-column>
            <el-table-column prop="tax" label="已出租面积(㎡)" width="210">
            </el-table-column>
            <el-table-column prop="tax" label="空置面积(㎡)" width="210">
            </el-table-column>
            <el-table-column prop="tax" label="空置率" width="210">
            </el-table-column>
            <el-table-column prop="tax" label="空置原因" width="210">
            </el-table-column>
            <el-table-column prop="tax" label="房屋状态" width="210">
            </el-table-column>
            <el-table-column
              prop="tax"
              label="租赁价格区间(元/月·㎡)"
              width="210"
            >
            </el-table-column>
            <el-table-column prop="tax" label="产权种类" width="210">
            </el-table-column>
            <el-table-column prop="tax" label="物业类型" width="210">
            </el-table-column>
            <el-table-column prop="tax" label="有无地下车库" width="210">
            </el-table-column>
            <el-table-column prop="tax" label="有无客电梯" width="210">
            </el-table-column>
            <el-table-column prop="tax" label="有无货梯" width="210">
            </el-table-column>
            <el-table-column prop="tax" label="有无卸货平台" width="210">
            </el-table-column>
            <el-table-column prop="tax" label="有无污水处理设施" width="210">
            </el-table-column>
            <el-table-column prop="tax" label="有无危化品中间仓" width="210">
            </el-table-column>
          </el-table>
        </div>
      </el-main>
    </el-container>
  </div>
</template>
<script>
import L from 'leaflet'
import '@supermap/iclient-leaflet'
import 'leaflet-editable'
import 'leaflet.markercluster'
import 'leaflet.markercluster/dist/MarkerCluster.css'
import 'leaflet.markercluster/dist/MarkerCluster.Default.css'
import gridBar from '@/components/industryHousing/gridBar.vue'
import FlexLine from '@/components/industryHousing/flexLine.vue'
import Map from '@/components/map'
import chartCommon from '@/components/chartCommon/index'
export default {
  components: {
    gridBar,
    FlexLine,
    Map,
    chartCommon
  },
  data () {
    return {
      mapUrl_cyjq:
        `${window.BASE_URL.superMapUrl}/iserver/services/map-ps/rest/maps/cyjq@ps`,
      mapDataUrl_cyjq:
        `${window.BASE_URL.superMapUrl}/iserver/services/data-ps/rest/data`,
      map_cyjq: '',
      industryType: '0',
      ActivedItemType: 0,
      zjType: 1,
      houseType: 1,
      itemList: [
        { name: '街道', value: '--' },
        { name: '社区', value: '--' },
        { name: '园区地址', value: '--' },
        { name: '园区类型', value: '--' },
        { name: '园区占地面积(㎡)', value: '--' },
        { name: '园区运营管理', value: '--' },
        { name: '园区边界', value: '--' },
        { name: '租金单价（元/月.m²）', value: '--' },
        { name: '联系方式', value: '--' },
        { name: '水费平均价格（元/m³）', value: '--' },
        { name: '电费平均价格（元/°）', value: '--' }
      ],
      houseUsingList: [
        { name: '自用面积(㎡)', value: '--' },
        { name: '已出售面积(㎡)', value: '--' },
        { name: '空置面积(㎡)', value: '--' },
        { name: '空置率', value: '--' },
        { name: '空置原因', value: '--' }
      ],
      rentStatusList: [
        { name: '租赁模式', value: '--' },
        { name: '租赁价格区间(元/月·㎡)', value: '--' }
      ],
      tableData: [
        {
          id: '001',
          jd: '龙城',
          community: '黄阁坑',
          park: '龙岗天安数码城',
          enterpriseName: '深圳众新产业数字科技有限公司',
          creditId: '58447755453348877',
          name: '黄超',
          phone: '0755-84456523',
          website: 'www.zhongxincy.com',
          isGs: '是',
          tax: '8.3',
          product: '9500'
        },
        {
          id: '001',
          jd: '龙城',
          community: '黄阁坑',
          park: '龙岗天安数码城',
          enterpriseName: '深圳众新产业数字科技有限公司',
          creditId: '58447755453348877',
          name: '黄超',
          phone: '0755-84456523',
          website: 'www.zhongxincy.com',
          isGs: '是',
          tax: '8.3',
          product: '9500'
        },
        {
          id: '001',
          jd: '龙城',
          community: '黄阁坑',
          park: '龙岗天安数码城',
          enterpriseName: '深圳众新产业数字科技有限公司',
          creditId: '58447755453348877',
          name: '黄超',
          phone: '0755-84456523',
          website: 'www.zhongxincy.com',
          isGs: '是',
          tax: '8.3',
          product: '9500'
        },
        {
          id: '001',
          jd: '龙城',
          community: '黄阁坑',
          park: '龙岗天安数码城',
          enterpriseName: '深圳众新产业数字科技有限公司',
          creditId: '58447755453348877',
          name: '黄超',
          phone: '0755-84456523',
          website: 'www.zhongxincy.com',
          isGs: '是',
          tax: '8.3',
          product: '9500'
        }
      ]
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      const vm = this
      // eslint-disable-next-line no-undef
      var dianzitu = L.supermap.tiandituTileLayer({
        url:
          'http://t{s}.tianditu.com/{layer}_{proj}/wmts?tk=c0ae855abbcd4b33d10e11e3af914aa7'
      })

      var tianditu = L.supermap.tiandituTileLayer({
        layerType: 'img',
        url:
          'http://t0.tianditu.gov.cn/img_c/wmts?tk=c0ae855abbcd4b33d10e11e3af914aa7'
      })
      var labelMap = L.supermap.tiandituTileLayer({
        isLabel: true,
        url:
          'http://t{s}.tianditu.com/{layer}_{proj}/wmts?tk=c0ae855abbcd4b33d10e11e3af914aa7'
      })
      var tdtDz = L.layerGroup([labelMap, dianzitu])
      var tdtYx = L.layerGroup([tianditu, labelMap])
      // var qu = L.supermap.tiledMapLayer(this.map_qu)
      this.jiedao = L.supermap.tiledMapLayer(this.map_jd)
      var qujie = L.supermap.tiledMapLayer(this.map_qujie)
      this.yuanqu = L.supermap.tiledMapLayer(this.map_yuanqu)
      this.build = L.supermap.tiledMapLayer(this.map_build)
      this.gaoxin = L.supermap.tiledMapLayer(this.map_gaoxin)
      this.sishang = L.supermap.tiledMapLayer(this.map_sishang)
      var cyjq = L.supermap.tiledMapLayer(this.mapUrl_cyjq)
      this.map = L.map('map', {
        preferCanvas: true,
        crs: L.CRS.EPSG4326,
        center: [22.7152291384486,114.347931662042],
        maxZoom: 16,
        zoom: 11,
        // layers: [tdtYx, qujie,gaoxin],
        layers: [tdtYx, cyjq],
        zoomControl: false,
        control: ['zoomControl']
      })
      this.querygroup = L.layerGroup().addTo(this.map)
      // this.gaoxin.addTo(this.querygroup)
      var baseMaps = {
        电子地图: tdtDz,
        影像图: tdtYx
      }
      var overlayMaps = {
        街道: this.jiedao,
        区界: qujie,
        园区: this.yuanqu,
        楼栋: this.build
      }
      // L.control.layers(baseMaps, overlayMaps).addTo(this.map)
      // L.marker([22.7152291384486,114.347931662042]).addTo(this.map)
      this.querygroup2 = L.layerGroup().addTo(this.map)
      this.map.on('click', function (evt) {
        vm.x = evt.latlng.lng
        vm.y = evt.latlng.lat
        vm.latlng = evt.latlng
        vm.Sqlquery()
      })
    },
    /**
     * 描述 点击查询调用的方法
     * @date 2022-04-12
     * @returns {any}
     */
    Sqlquery () {
      const vm = this
      var popupContent = null
      var marker
      //   var currentLayer
      if (this.querygroup2 != null) {
        this.querygroup2.clearLayers()
      }
      var point = L.circle([vm.y, vm.x], {
        radius: 1
      })
      // eslint-disable-next-line no-undef
      var geometryParam = new SuperMap.GetFeaturesByGeometryParameters({
        datasetNames: [
          '坪山产业集群:坪山产业集群'
          // 'baoan_1:T20201022_宝安区园区_1'
          // 'yuanqu:bldg'
          // 顺序有关系,把楼栋放在后面,那么点击楼栋查询的时候才能查到.
          // 如果放在前面,那么点击查询的时候 会查到园区,点不到楼栋,被覆盖了.
          // 放在后面的查询层级比较高
          // "zhuzhaibianjie:GCS_2000_所有住宅小区的边界20201019",
        ], // 这里data就是数据源  资溪就是数据集
        geometry: point,
        spatialQueryMode: 'INTERSECT'
      })
      L.supermap
        .featureService(this.mapDataUrl_cyjq)
        .getFeaturesByGeometry(geometryParam, function (serviceResult) {
          if (serviceResult.result) {
            L.geoJSON(serviceResult.result.features, {
              onEachFeature: function (feature, layer) {
                console.log(layer)
                // currentLayer = layer
                // vm.getBuildingsByParkBounds(currentLayer)

                // popupContent =
                // '<p class="popTitle">'+feature.properties.YQMC+'<br></p>'
                // '总规划面积' +
                //   '：' +
                //   feature.properties.ZGJMJ +
                //   '<br>' +
                //   '总规划面积' +
                //   ':' +
                //   feature.properties.ZGJMJ +
                //   '<br>' +
                //   '用地类型' +
                //   ':' +
                //   feature.properties.LAND_TYPE +
                //   '<br>' +
                //   '用房类型' +
                //   ':' +
                //   feature.properties.ZTLX
                popupContent = `<div class="popup">
              <div class="echart-container">
                <div class="cont_title">${feature.properties.YQMC}</div>
                <p>总规划面积：${feature.properties.ZGHMJ}km²</p>
                <p>规划工业用地：${feature.properties.GHGYYD}km²</p>
                <p>未来可释放产业用地：${feature.properties.WLKSFCYYD}km²</p>
                <p>拟布局产业：${feature.properties.NBJCY}</p>
                <p class="last_descr">${feature.properties.JS}</p>
              </div>
            </div>`
              }
            }).addTo(vm.querygroup2)
          }
          if (popupContent != null) {
            marker = L.marker(vm.latlng).addTo(vm.querygroup2)
            marker.bindPopup(popupContent).openPopup()
          }
        })
    },
    selectItemType (val) {
      this.ActivedItemType = val
    },
    selectType (type) {
      this.zjType = type
    },
    selectHouseType (type) {
      this.houseType = type
    },
    tableHeadStyle ({ columnIndex }) {
      if (
        columnIndex == 0 ||
        columnIndex == 1 ||
        columnIndex == 2 ||
        columnIndex == 3
      ) {
        return `
                background: #0e466f;
                font-size: 14px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 14px;`
      } else {
        return `
                background: rgba(21, 154, 255, 0.3);
                box-shadow: inset 0px 12px 10px -9px rgb(21 154 255 / 60%)
                font-size: 14px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 14px;`
      }
    },
    tableCellStyle ({ columnIndex }) {
      if (columnIndex == 3) {
        return `font-size: 14px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #FFFFFF;
                box-shadow: 6px 0px 6px -3px rgb(21 154 255 / 50%);
                `
      } else if (columnIndex == 2) {
        return `
                font-size: 14px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 14px;`
      } else {
        return `font-size: 14px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #D0DEEE;
                line-height: 14px;`
      }
    },
    tableRowClassName ({ rowIndex }) {
      if (rowIndex % 2 == 0) {
        return 'dark-row'
      } else {
        return 'light-row'
      }
    }
  }
}
</script>
<style lang="less">
.el-table .dark-row {
  background: #0b2c44 !important;
}

.el-table .light-row {
  background: #0f3e60 !important;
}

</style>
<style lang="less" scoped>
#map {
  width: 100%;
  height: 100%;
}
.industrialChain {
  padding: 20px;
  .aside {
    width: 44.375rem /* 710/16 */ !important;
  }
  .aside_top {
    width: auto;
    height: 385px;
    .tabs {
      display: flex;
      margin-top: 1.875rem /* 30/16 */;
      .left,
      .right {
        cursor: pointer;
        width: 5.625rem /* 90/16 */;
        position: relative;
        span {
          width: 100%;
          position: absolute;
          left: 0;
          top: 0;
          text-align: center;
          line-height: 30px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #d0deee;
        }
      }
      .zjActviedClass {
        span {
          color: #fff;
        }
      }
    }
    .itemList2 {
      margin-top: 13px;
      padding-left: 10px;
      .infoTitle {
        position: relative;
        height: 2.1875rem /* 35/16 */;
        display: block;
        width: 100%;
        margin-top: 0.9375rem /* 15/16 */;
        img {
          height: 100%;
          width: 100%;
        }
        span {
          position: absolute;
          top: 0.375rem /* 6/16 */;
          left: 0.625rem /* 10/16 */;
          font-size: 0.875rem /* 14/16 */;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #3be8ff;
        }
      }
      ul {
        display: flex;
        flex-wrap: wrap;
        li {
          width: 33%;
          margin-top: 19px;
          .title {
            position: relative;
            img {
              height: 10px;
            }
            span {
              font-size: 14px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #d0deee;
              position: absolute;
              top: 2px;
              left: 3px;
            }
          }
          p {
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #ffffff;
            margin-top: 5px;
            padding-left: 2px;
          }
        }
      }
    }
    .itemList {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      //   display: flex;
      // flex-wrap: wrap;
      // justify-content: space-between;
      .activedItem {
        background: none !important;
        border: none !important;
        background-image: url('../assets/images/industryHousing/activedBg.png') !important;
        background-size: 100% 100% !important;
      }
      .itemOne {
        flex: 1;
        width: 49% /* 350/16 */;
        height: 80px;
        background: rgba(21, 154, 255, 0.2) no-repeat;
        border: 1px solid #409eff;
        // box-shadow: 0px 6px 18px 0px rgba(21, 154, 255, 0.7);
        padding: 1.25rem /* 20/16 */ 0.9375rem /* 15/16 */;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-right: 11px;
        margin-top: 11px;
        cursor: pointer;
        &:nth-of-type(2),
        &:nth-of-type(4) {
          margin-right: 0;
        }
        .left {
          display: flex;
          align-items: center;
          img {
            width: 2.625rem /* 42/16 */;
            height: 3.0625rem; /* 49/16 */
          }
          span {
            font-size: 0.875rem /* 14/16 */;
            font-family: Source Han Sans CN;
            font-weight: normal;
            color: #fefefe;
            margin-left: 0.625rem /* 10/16 */;
          }
        }
        .right {
          .num {
            font-size: 0.875rem /* 14/16 */;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #159aff;
          }
          .bar {
            width: 9.375rem /* 150/16 */;
            height: 8px;
            background: #6191b450;
            position: relative;
            z-index: 8;
            .activedBar {
              opacity: 1;
              z-index: 10;
              position: relative;
              width: 50%;
              height: 8px;
              background: linear-gradient(90deg, #159aff 0%, #3be8ff 100%);
            }
          }
        }
      }
    }
  }
  .aside_center {
    height: auto;
  }
  .aside_bottom {
    width: 100%;
    height: auto;
    background: #0f375370;
    border: 1px solid #159aff;
    box-shadow: inset 0px 6px 18px 0px rgba(21, 154, 255, 0.9);
    margin-top: 20px;
    .chart1 {
      height: 355px;
    }
    .chart2 {
      margin-top: 40px;
      padding-left: 1.25rem /* 20/16 */;
      width: 100%;
      .tabs {
        display: flex;
        .left,
        .right {
          cursor: pointer;
          width: 5.625rem /* 90/16 */;
          position: relative;
          span {
            width: 100%;
            position: absolute;
            left: 0;
            top: 0;
            text-align: center;
            line-height: 30px;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #d0deee;
          }
        }
        .zjActviedClass {
          span {
            color: #fff;
          }
        }
      }
      .chart {
        width: 100%;
        height: 355px;
      }
    }
  }
  .main {
    width: 100% !important;
    .map_chart {
      height: 100%;
      width: 100%;
    }
    .table {
      margin-top: 30px;
    }
  }
}
</style>
